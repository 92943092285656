import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import './App.css';
import checkmark from './resources/greencheck.jpg';
import skull from './resources/skull.gif';

function App() {

  const [isShowingAlert, setShowingAlert] = useState(true);
  const [userWeight, setWeight] = useState(289);
  const [currentPage, setCurrentPage] = useState('')
  useEffect(() => {
    setTimeout(() => {
      setShowingAlert(false)
    }, 5000)


    // console.log(userWeight)
    // setWeight(39)
    // console.log(userWeight)
    // setWeight(userWeight)

  }, [])

  function clickHandler() {  
    setShowingAlert(false)
  }
  function resetAlert(){
    setShowingAlert(true);
    setTimeout(() => {
      setShowingAlert(false)
    }, 5000)

  }
      
  return (
    <div className="App">
      
      {isShowingAlert ?
      <div className='container'>
        <div className='row justify-content-center '>
          <div className="col-12 col-lg-8 underText">

          <div className={`App-header alert alert-success`}>
            <img 
              src={skull} 
              className="App-logo" 
              alt="logo" 
            />
            <div id="warn">
              You could have been hacked!
            </div>
            <button id='skip' onClick={clickHandler}></button>
          </div>
          </div>
        </div>
      </div>
      :
      <div className='container'>
        <div className='row justify-content-center '> 
          <div className="col-12 col-lg-8 underText">
            <div id="afterWarn" className='text-center'>
              Your first call is to Restoration Security.             
	    </div>
	    <a className="pnumber" href="tel:713-876-2610">713-876-2610</a>
	    <br/>
            <a className="findMore" href='https://www.restoration-mt.com/contact-us/'><br/><img className={"checkmark"} alt="check" src={checkmark}/><br/>Find out more!</a>
          </div>
        </div>
      </div>
      }

	  <title>HACKED</title>
    </div>

  );
}

export default App;
